class Flow extends ShaderLayer
	# webgl : true
	preload : () ->
		# @vert = loadStrings "default.vert"
		# @draw_frag = loadStrings "flow_draw.frag"
		# @show_frag = loadStrings "flow.frag"
	setup : () ->
		@pos = createVector 0.5, 0.5
		@direction = createVector 0, 1
		@res = 128

	focus : () ->
		@layers.topograph.focus()
		@layers.topograph.set_draw_layer(1.0)
	defocus : () ->
		@image.resetShader()
	draw : (delta, time) ->
		@layers.topograph.draw delta, time

	get_direction : (pos) ->
		t = @layers.topograph
		createVector(
			t.bipolar_byte t.get_pixel(pos, 1)
			t.bipolar_byte t.get_pixel(pos, 2)
		)
	press : () ->
		@start()
	tap : (p) ->
		# @pos.set p
	pan : (p, delta) ->
		@layers.topograph.pan p, delta
